<!--
 功能名称:千里眼平台-技师
 开发人:梁高权
 创建时间:2021/03/25
 最后修改时间:2021/03/25
-->
<template>
  <div class="container">
    <technician-state v-if="tabIndex==0" @changeTab="changeTab"></technician-state>
    <technician-analysis v-if="tabIndex==1" @changeTab="changeTab"></technician-analysis>
    <technician-ranking v-if="tabIndex==2" @changeTab="changeTab"></technician-ranking>
  </div>
</template>

<script>
export default{
  name:"technician",
  data(){
    return{
      tabIndex:0,
    }
  },
  components:{
    'technician-state':() => import('./technician-state/technician-state'),
    'technician-analysis':() => import('./technician-analysis/technician-analysis'),
    'technician-ranking':() => import('./technician-ranking/technician-ranking')
  },
  methods:{
    changeTab(index){
      this.tabIndex = index
    }
  },
  mounted () {
    this.$store.commit('updateIsShowTabBar',true)
    this.$store.commit("updateIsShowPickStore",true)
    this.$store.commit("updateAllowPull",true)
  }
}
</script>

<style lang="scss" type="text/scss" scoped>
    @import "~@/assets/style/public.scss";
    .container{
        display:flex;
        flex-direction:column;
        height:100%;
        overflow:hidden;
    }
</style>
